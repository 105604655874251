/* .footer_section { */
/* I might give the footer section a background color */
/*  background-color: #2d3e50;  #031011 */
/*color: #ffffffbb; */
/* position: relative; */
/* color: #727272; */
/* height: 400px; */
/* height: 510px; */
/* } */

/* .footer_section::before {
	content: "";
	width: 100%;
	height: 450px;
	/* background: url('../img/footer_bg_gb.svg'); */
/*background: url("../../images/EWS-WEBSITE-FOOTER-grey.svg");
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
} */

.footer_section {
	margin-top: 100px;
	background: url("../../images/EWS-WEBSITE-FOOTER-grey.svg");
	background-repeat: no-repeat;
	background-size: cover;
	/* position: absolute;
	bottom: 0; */
	height: 400px;
}

.footer_bt_cn {
	position: relative;
}

.footer_content {
	padding: 20px 0;
	position: absolute;
	left: 0;
	right: 0;
	top: 130px;
}

.footer_content .footer_socials,
.footer_content .contact_info,
.footer_content .footer_form {
	text-align: left;
}

.footer_socials {
	text-align: left;
}

.footer_socials img {
	width: 120px;
	transition: 0.5s ease-in-out;
	/* -webkit-transition: .5s ease-in-out; */
	position: relative;
	top: 15px;
}

.footerlogoanim {
	position: absolute;
	transform: scale(1.4);
}

.footer_section h5 {
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 600;
	color: #e8e8e8;
}

/* .footer_socials img:hover{
    background: url("../img/footer-logo-img.png");
    width: 123px;
    background-repeat: no-repeat;
    background-size: cover;
    transition: .5s ease-in-out;
} */

/* .footer_form{
    width: 20%;
    margin: auto; 
}*/

.footer_form {
	margin-top: 20px;
}

.footer_form form {
	display: flex;
	align-items: center;
}

.footer_form h5 {
	/*color: #fff;     #00b1a8 */

	color: #e8e8e8;
}

.form-control {
	border: 2px solid #00252a91 !important;
	border-radius: 10px 0px 0px 10px !important;
	margin: 10px 0;
}

#subscribe_btn {
	background-color: rgb(0, 0, 0);
	color: #ffffff;
	border: 2px solid #1d1d1d;
	/* border-radius: 3px; */
	border-radius: 0px 10px 10px 0px;
	transition: 0.3s ease-in-out;
}

#subscribe_btn:hover {
	background-color: #1d1d1d;
	color: #ffffff;
	border: 2px solid #1d1d1d;
}

.social_handles {
	display: flex;
	flex-direction: row;
}

.social_handles a {
	text-decoration: none;
	margin-right: 35px;
	color: #e8e8e8;
	font-size: 35px;
	padding: 10px 11px;
	display: flex;
	border: 2px solid #e8e8e8;
	border-radius: 50%;
}

.margin_top {
	margin-top: 20px;
}

.social_handles a svg {
	transition: 0.3s ease-in-out;
	font-size: 25px;
	opacity: 0.5;
}

.social_handles a .fa-facebook:hover {
	color: #3b5998;
	opacity: 1;
}

.social_handles a .fa-instagram:hover {
	/* background: -webkit-linear-gradient(
		312deg,
		#515bd4,
		#8134af,
		#dd2a7b,
		#feda77,
		#f58529
	); */
	color: #dd2a7b;
	/* -webkit-background-clip: text;
	-webkit-text-fill-color: transparent; */
	opacity: 1;
}

.social_handles a .fa-twitter:hover {
	color: #00acee;
	opacity: 1;
}

.social_handles a .fa-linkedin-in:hover {
	color: #0e76a8;
	opacity: 1;
}

.contact_info {
	font-size: 18px;
	margin-top: 20px;
}

.contact_info .footer_contact_container {
	font-size: 16px;
}

.contact_info .footer_contact_container p {
	font-size: 18px;
	font-weight: 400;
	color: #c9c9c9;
}

.logo-slogan {
	width: 100%;
	text-align: center;
	padding-top: 40px;
}

.logo-slogan img {
	width: 150px;
}

.logo-slogan p {
	color: #ffffff;
	letter-spacing: 5px;
	padding: 10px 0;
	text-align: center;
}

/********************* Placeholder Newsletter From *********************/
/* .footer_form div {
	display: flex;
} */
.footer_form div input {
	border: none;
	border-radius: 9px;
	padding: 5px;
}
.footer_form div button {
	border: none;
	border-radius: 9px;
	background-color: #000000;
	color: #ffffff;
	padding: 5px 50px;
	margin-top: 7px;
}
.footer_form div div {
	color: #ffffff !important;
}
/********************* Placeholder Newsletter From *********************/

/********************* Tablet *********************/
@media (max-width: 990px) {
	.footer_section {
		height: 450px;
	}
	.footer_content {
		top: 70px;
	}
	.footer_section h5 {
		font-size: 16px;
	}
	.logo-slogan p {
		font-size: 14px;
	}
}

/********************* Mobile Phone *********************/
@media screen and (max-width: 768px) {
	.footer_section {
		/* overflow: hidden; */
		height: 620px;
	}

	.footer_content {
		top: 50px;
	}
	.footer_content .footer_socials,
	.footer_content .contact_info,
	.footer_content .footer_form {
		text-align: center;
		margin: 20px 0px;
	}

	.social_handles {
		justify-content: space-evenly;
	}

	.social_handles a {
		margin-right: 0;
	}

	.footer_form form {
		width: 90%;
		margin: auto;
	}

	.logo-slogan p {
		font-size: 15px;
		letter-spacing: 3px;
	}
	/******************************************/
	.footer_form div {
		display: block;
	}
	.footer_form div input {
		border-radius: 8px;
		text-align: center;
	}
	.footer_form div button {
		border: 0.9px solid #fff;
		margin-top: 10px;
		border-radius: 10px;
		padding: 5px 50px;
	}
	/******************************************/
}
