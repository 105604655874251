header {
	/* background-color: #1e1e1e; */
	/* border-bottom: 1px solid #35353553; */
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	transition: 0.2s ease-in-out;
}

header.sticky {
	background: #fff;
	box-shadow: 0px 0px 10px 0px #000000bf;
}

.navigation {
	/* background: #1e1e1e; */
	height: 80px;
	display: flex;
	justify-content: space-between;
	/* padding: 0.5rem calc((100vw - 1000px) / 2); */
	z-index: 10;
	position: relative;
	/* border-bottom: 1px solid #fff; */
}

.logo-container {
	width: 100px;
}

.logo-container img {
	width: 100%;
}

.hamburger {
	display: none;
	color: #00252a;
}

/* .hamburger svg {
	transform: rotateY(180deg);
} */

.navmenu_div {
	display: flex;
	align-items: center;
}

.navmenu {
	display: flex;
	align-items: center;
	margin-bottom: 0;
}

.logo-container,
.navmenu li {
	display: flex;
	align-items: center;
	padding: 0 15px;
	height: 100%;
	cursor: pointer;
	transition: 0.2s ease-in-out;
}

.navmenu a {
	text-decoration: none;
	color: #00252a;
	text-transform: capitalize;
	font-size: 16px;
	font-weight: 500;
}

.navmenu a:hover {
	color: #00252a;
	transition: 0.2s ease-in-out;
}

.navmenu li .nav_link::after {
	content: "";
	width: 0;
	height: 3px;
	display: block;
	background: #00252a;
	transition: 0.3s ease-in-out;
	margin: auto;
}

.navmenu li .nav_link:hover::after {
	width: 100%;
}

.active {
	color: #3257e1;
}

.navmenu .contact-us-btn a {
	border-radius: 25px;
	border: 2px solid #00252a;
	outline: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;
	margin-left: 10px;
	padding: 10px 27px;
}

.navmenu .contact-us-btn a:hover {
	transition: all 0.2s ease-in-out;
	background: #00252a;
	color: #fff;
	text-decoration: none;
}

/********************* Tablet *********************/
@media (max-width: 990px) {
	.logo-container {
		width: 15%;
	}

	.navmenu_div {
		display: none;
	}

	.hamburger {
		display: flex;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 75%);
		font-size: 1.8rem;
		cursor: pointer;
	}

	.hamburger svg {
		font-size: 35px;
	}
}

/********************* Mobile Phone *********************/
@media screen and (max-width: 768px) {
	.logo-container {
		width: 25%;
	}

	.navmenu {
		display: none;
	}
}
