.portfolio-container {
	/* margin-top: 100px; */
	height: 100vh;
	margin-bottom: 50px;
	position: relative;
	overflow: hidden;
}

.portfolio-container .pc-ab-img {
	position: absolute;
	border-radius: 50%;
}

.portfolio-container .pc-ab-img-1 {
	top: 100px;
	left: -100px;
	width: 180px;
	height: 180px;
	background-color: #178b98;
}
.portfolio-container .pc-ab-img-2 {
	top: 180px;
	left: 450px;
	width: 80px;
	height: 80px;
	background-color: #feb100;
}
.portfolio-container .pc-ab-img-3 {
	top: -100px;
	right: -80px;
	width: 380px;
	height: 380px;
	background-color: #e51952;
}
.portfolio-container .pc-ab-img-4 {
	top: 550px;
	left: 250px;
	width: 135px;
	height: 135px;
	background-color: #de4217;
}
.portfolio-container .pc-ab-img-5 {
	top: 500px;
	left: 770px;
	width: 55px;
	height: 55px;
	background-color: #f78600;
}
.portfolio-container .pc-ab-img-6 {
	top: 330px;
	left: 1150px;
	width: 210px;
	height: 210px;
	background-color: #34af80;
}

.portfolio-header-text {
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;
	text-align: center;
}

.portfolio-header-text h1,
.portfolio-header-text p {
	text-transform: capitalize;
}

.portfolio-header-text h1 {
	font-size: 83px;
	font-weight: 200;
}

.portfolio-header-text h1 span {
	font-weight: 700;
	/* color: #00252a; */
	/* mix-blend-mode: luminosity; */
}

.portfolio-header-text p {
	font-size: 18px;
	font-weight: 700;
	letter-spacing: 0.5px;
}

.project-item {
	align-items: center;
	border: 4px solid #00252a15;
	border-radius: 50px;
	padding: 35px 40px;
  margin-bottom: 35px;
}

.project-info h3 {
	font-size: 35px;
	/* font-weight: 700; */
}

.project-info p {
	font-size: 20px;
}

.project-image img {
	width: 100%;
}

.projects-techs {
	display: flex;
}

.project-tech {
	display: flex;
	align-items: center;
	margin-right: 20px;
	font-size: 16px;
}

.project-tech::before {
	content: "";
	width: 12px;
	height: 12px;
	background-color: #178b98;
	display: block;
	margin-right: 5px;
	border-radius: 100%;
}

.project-tech:nth-child(1)::before {
	background-color: #e51952;
}
.project-tech:nth-child(2)::before {
	background-color: #178b98;
}
.project-tech:nth-child(3)::before {
	background-color: #feb100;
}

.view-project {
	display: flex;
	align-items: center;
	text-decoration: none;
	color: #00252a;
	transition: all 0.3s ease-in-out;
	margin-top: 35px;
	/* background-color: #de4217; */
	padding: 15px 0;
	width: 50%;
	font-size: 20px;
}

.view-project .view-project-arrow {
	margin-left: 5px;
	transition: all 0.3s ease-in-out;
}

.view-project:hover {
	color: #00252a;
}

.view-project:hover .view-project-arrow {
	transform: translateX(18px);
}

/********************* Tablet Landscape ************************/
@media (max-width: 1194px) {
	.portfolio-container .pc-ab-img-1 {
		top: 100px;
		left: -100px;
		width: 170px;
		height: 170px;
	}
	.portfolio-container .pc-ab-img-2 {
		top: 200px;
		left: 300px;
		width: 70px;
		height: 70px;
	}
	.portfolio-container .pc-ab-img-3 {
		top: -120px;
		right: -70px;
		/* width: 380px;
		height: 380px; */
	}
	.portfolio-container .pc-ab-img-4 {
		top: 550px;
		left: 100px;
		width: 140px;
		height: 140px;
	}
	.portfolio-container .pc-ab-img-5 {
		top: 500px;
		left: 620px;
		width: 50px;
		height: 50px;
	}
	.portfolio-container .pc-ab-img-6 {
		top: 370px;
		left: 990px;
		width: 190px;
		height: 190px;
	}
	.project-info h3 {
		font-size: 28px;
	}
	.project-info p {
		font-size: 18px;
	}
	.project-tech {
		font-size: 13px;
	}
	.project-tech::before {
		width: 7px;
		height: 7px;
	}
	.view-project {
		font-size: 17px;
	}
}

/********************* Tablet ************************/
@media (max-width: 990px) {
	.portfolio-container .pc-ab-img-1 {
		top: 250px;
	}
	.portfolio-container .pc-ab-img-2 {
		top: 380px;
	}
	.portfolio-container .pc-ab-img-3 {
		top: -120px;
		right: -70px;
	}
	.portfolio-container .pc-ab-img-4 {
		top: 750px;
	}
	.portfolio-container .pc-ab-img-5 {
		top: 700px;
		left: 500px;
	}
	.portfolio-container .pc-ab-img-6 {
		top: 490px;
		left: 630px;
	}
	.portfolio-header-text p {
		letter-spacing: 0px;
	}
  .project-item {
    padding: 35px 25px;
    /* margin-bottom: 35px; */
  }
  .project-info h3 {
		font-size: 18px;
	}
	.project-info p {
		font-size: 14px;
	}
	.project-tech {
		font-size: 10px;
	}
	.project-tech::before {
		width: 5px;
		height: 5px;
	}
	.view-project {
		font-size: 14px;
    width: 60%;
	}
  .view-project img{
		width: 40px;
	}
}

/* Mobile Phone */
@media screen and (max-width: 768px) {
	.portfolio-container .pc-ab-img-1 {
		top: 250px;
		left: -70px;
		width: 120px;
		height: 120px;
	}
	.portfolio-container .pc-ab-img-2 {
		top: 500px;
	}
	.portfolio-container .pc-ab-img-3 {
		top: -20px;
		right: -70px;
		width: 250px;
		height: 250px;
	}
	.portfolio-container .pc-ab-img-4 {
		top: 550px;
		left: 10px;
		background-color: #34af80;
	}
	.portfolio-container .pc-ab-img-5 {
		display: none;
	}
	.portfolio-container .pc-ab-img-6 {
		display: none;
	}
	.portfolio-header-text h1 {
		font-size: 65px;
	}
	.portfolio-header-text p {
		font-size: 13px;
		font-weight: 700;
		letter-spacing: 0px;
	}
  .project-item {
    padding: 35px 15px;
    margin-bottom: 25px;
  }
  .view-project {
		font-weight: 600;
    width: 100%;
	}
}
